<template>
    <div class="chinaMap"></div>
</template>

<script>
import "echarts/map/js/china.js"

export default {
    props: {
        mapData: {
            type:Array
        },
        geoCoordMaps: Object,
    },
    name: '',
    data() {
        return {
            
        }
    },
    methods: {
        // getMapData(){
        //     this.$http.post('/api/admin/big_data').then(res=>{
        //       console.log(res)
        //       let {code,data} = res.data
        //       if(code==0){
        //         this.data = data.map_data.city_order
        //         this.geoCoordMap = data.map_data.city_location
        //         this.total_data = data.total_data
        //         this.setMap()
        //       }
              
        //     })
        // },
        setMap() {
        var geoCoordMap = this.geoCoordMaps
        var data = this.mapData

            var convertData = function (data) {
                var res = [];
                 for (var i = 0; i < data.length; i++) {
                    var geoCoord = geoCoordMap[data[i].name];
                    
                    if (geoCoord) {
                        // console.log(data[i].value)
                    res.push({
                        name: data[i].name,
                        value: geoCoord.concat(data[i].value)
                    });
                    // console.log(res)
                    }
                }
                // console.log(res)
                return res;
            };

            let option = {
   // backgroundColor: '#404a59',
  title: {
        text: '酒店签约数预览',
        subtext: '数据统计',
        left: 'center',
		top:'40',
        textStyle: {
            color: '#fff'
        }
    },
    tooltip : {
        trigger: 'item'
    },
  
    geo: {
        map: 'china',
        center: [104.114129, 37.550339],
        label: {
            emphasis: {
                show: false
            }
        },
        roam: false,
		zoom:1.2,
        itemStyle: {
            normal: {
                areaColor: 'rgba(2,37,101,.5)',
                borderColor: 'rgba(112,187,252,.5)'
            },
            emphasis: {
                areaColor: 'rgba(2,37,101,.8)'
            }
        }
    },
    series : [
        {
            name: '签约数量',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: convertData(data),
            symbolSize: function (val) {
                console.log()
                return val[2] / 10;
            },
             encode: {
                    value: 2
                },
            label: {
                normal: {
                    formatter: '{b}',
                    position: 'right',
                    show: false
                },
                emphasis: {
                    show: true
                }
            },
            itemStyle: {
                normal: {
                    color: '#ffeb7b'
                }
            }
        }

    ]
};
            let myChart = this.$echarts(this.$el);

            myChart.clear();
            myChart.resize()
            myChart.setOption(option);
        },
    },
    created(){
        // this.getMapData()
        // this.setMap();

    },
    mounted() {
        setTimeout(() => {
        this.setMap();

        },100);
    },

}
</script>

<style lang="less" scoped>
.chinaMap {
    height: 100%;
}

</style>